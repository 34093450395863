import React from 'react';
import Header from '../../../components/ui/header/Header';
import AboutNomadCard from '../../sections/about/About';

const About =(props)=> {
    return (
        <React.Fragment>
            <Header title="About Us"></Header>
            <AboutNomadCard windowSize={props.windowSize}></AboutNomadCard>
        </React.Fragment>
    );
}

export default About;