import React from 'react';
import AboutDirectImage from '../../../assets/images/nomad_about_us_logo_700x400.png';

const About = (props) => {
    return(
        <React.Fragment>
            <section className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-lg -6">
                        <div className="text-center">
                            <img className=".img-fluid. text-center" src={AboutDirectImage} alt="About Direct" />
                        </div>
                        </div>
                        <div className="col-xs-12 col-lg -6">
                            <h4 className="about-title">Behind Nomad Direct</h4>
                            <div className="about-text">
                                <p>
                                    Nomad Direct was built by Nomad Temporary Housing, the global service leader in temporary housing. With over 50+ major service awards, and several #1 industry rankings, Nomad stands apart. We currently deliver an unparalleled level of service to over 25 relocation management companies, as well as hundreds of global employers.
                                </p>
                                <p>
                                    Nomad Temporary Housing is one of the world’s largest providers of quality temporary accommodations.  Working in 90 countries, via local partnership with 1300 brands, Nomad accesses over 250,000 housing options.
                                </p>
                                <p>
                                    Nomad provides a service experience that is unbiased and unequalled, worldwide.
                                </p>
                            </div>
                            <div className="about-text">
                                Learn more about the team, awards and testimonials
                                <a className="about-link about-text" href="https://nomadtemphousing.com/about/overview" target="_blank" rel="noopener noreferrer"> here</a>
                            </div>
                        </div>
                    </div>
            </div>
            </section>
        </React.Fragment>
    );
} 
export default About;