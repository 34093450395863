import React from 'react';
import Tablet from '../../../assets/images/tablet.png';

const unbiased = (props) => {
    return(
        <section id="unbiased" className="unbiased">
            <h1 className="section-header">Unbiased Features</h1>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center">
                        <img className="image-fluid" src={Tablet} alt="nomad website ipad"/>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
                    <ul>
                        <li>Choice of vetted suppliers, defined by market</li>
                        <li>Availability of over 23,500 properties, with unbiased star ratings and evaluations</li>
                        <li>Provision of even playing field, no premium listings or potential to pay for better placement during search</li>
                        <li>Use of specific filters allow your team to be market experts</li>
                        <li>Extreme functionality due to utilization of detailed supplier and client profiles</li>
                        <li>Ease of booking</li>
                    </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default unbiased;
